.xdc__select__option {
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.xdc__select {
  min-width: 160px;
}
