/* Bootstrap overrides
-------------------------------------------------- */

$body: #18191a;
$headings-font-weight: 300 !important;
$primary: #ffc629;
$light: #fafafa;
$card-border-radius: 0.75rem;
$card-inner-border-radius: subtract($card-border-radius, 1px);

$headings-font-weight: 300 !important;
$a: #ffc629;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 16px;
}

body {
  background-color: $body;
}

.wrapper {
  min-height: 100vh;
}

.text-main {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.text-secondary {
  color: #9ba5b4;
  fill: #9ba5b4;
}

input {
  accent-color: $primary;
}

.dapp-core-component__main__text-primary {
  color: $primary !important;
}

h4,
.h4 {
  font-size: 1.25rem;
  margin: 0;
}

.btn-logout,
.btn-close,
.btn-reload {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: #9ba5b4;
  background-color: #18191a;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: #fff !important;
    background-color: #18191a !important;
  }
}

/* Scrollbar
-------------------------------------------------- */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin: 0.75rem 0;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 0.75rem;
}

/* Converter Layout
-------------------------------------------------- */
.card {
  color: #9ba5b4;
  background-color: #242526;
  box-shadow: 0 0.6rem 1rem 0 rgb(0 0 0 / 6%);
  border: none;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 2rem;
  border-radius: 0.75rem;
}

.page-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.2;

  span {
    font-size: 0;
    visibility: hidden;
  }

  img {
    height: 6rem;
    width: auto;
  }
}

.page-description {
  color: #9ba5b4;
  font-size: 1rem;
}

.my-spacer {
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
}

.page-title {
  margin-top: 2.5rem;
}

/* Converter
-------------------------------------------------- */
.btn-connect {
  color: #000 !important;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.45rem;
}

.card-info {
  background-color: #18191a;
  padding: 1.5rem;

  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.token-table {
  width: 100%;

  .table-header {
    padding-top: 0;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .table-col.title {
      cursor: pointer;
    }
  }

  .table-body {
    background-color: #18191a;
    padding: 1.5rem;
    min-height: 350px;
    max-height: 500px;
    overflow-y: scroll;

    .table-row {
      margin-bottom: 1rem;

      &:hover {
        cursor: pointer;
        opacity: 75%;
      }
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 3fr));
      place-items: center;
      gap: 2rem 1rem;

      .table-row {
        margin-bottom: 0;
      }

      .table-col.title {
        display: flex;
        flex-grow: 0;
        justify-content: center;
        align-items: center;

        div {
          flex-direction: column !important;

          .mr-3 {
            margin-right: 0 !important;
            margin-bottom: 0.5rem !important;
          }
        }
      }

      .table-col.value {
        display: none;
      }
    }
  }

  .table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .table-col {
    min-width: 110px;

    &.title {
      flex-grow: 1;
    }

    &.value {
      text-align: right;
    }
  }

  .token-image {
    width: auto;
    height: 2rem;
    border-radius: 2rem;
  }
}

/* Welcome
-------------------------------------------------- */
.welcome-card {
  font-size: 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    flex-grow: 1;

    a {
      margin: 0 !important;
      color: #fff;
      text-decoration: none;
    }
  }
}

/* Login Modal
-------------------------------------------------- */
.modal-backdrop {
  z-index: 980;
}

.login-modal {
  font-size: 16px;
  z-index: 990;

  .modal-dialog {
    color: #9ba5b4;
    box-shadow: 0 0.6rem 1rem 0 rgb(0 0 0 / 6%);

    .modal-content {
      background-color: #242526;
      border-radius: 0.75rem;
      padding: 2rem;

      .modal-header,
      .modal-body {
        padding: 0;
        border: 0;
      }

      .modal-header {
        display: flex;
        align-items: center;
      }

      .modal-title {
        font-size: 1.2rem;
      }
    }

    .dapp-core-component__main__btn {
      margin: 0 !important;
      margin-top: 1rem !important;
      color: #000;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.45rem;
      outline: none;
      border: none;
      background-color: $primary;
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.9rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.155rem;

  a {
    color: #9ba5b4;

    &:hover {
      color: #fff;
      text-decoration: none;
    }

    #heart {
      height: 16px;
      width: 16px;

      path {
        fill: $primary;
      }
    }
  }

  .social {
    justify-content: center;

    svg {
      height: 2rem;
      width: auto;
      fill: #9ba5b4;

      &:hover {
        fill: #fff;
      }
    }
  }

  .app-version {
    color: #9ba5b4;
    opacity: 60%;
    display: block;
  }
}

/* Referral
-------------------------------------------------- */
.claim-rewards-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .text-secondary {
    color: #18191a !important;
    opacity: 0.9;
  }

  .btn {
    padding: 1rem;
    width: 150px;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;

    i {
      font-size: 0.75rem;
    }
  }
}

.shine {
  color: #18191a;
  background-color: $primary;
  position: relative;
  overflow: hidden;

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 300px;
    height: 100%;
    background-image: linear-gradient(110deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0) 80%);
    top: 0;
    left: -300px;
    animation: shine 5s infinite linear;
  }
}

@keyframes shine {
  0% {
    left: -300px;
    opacity: 1;
  }

  10% {
    opacity: 0.8;
  }

  25% {
    opacity: 0.2;
  }

  30% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

.referral-info-card {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ol {
    padding-inline-start: 1rem;
  }
}

.referral-registered-card {
  .btn-copy {
    color: #9ba5b4;

    &:hover {
      color: #fff;
    }
  }
}

.referral-tag-input {
  display: block;
  color: #fff;
  background-color: #18191a;
  border: 0;
  padding: 1rem;
  outline: none;
  width: 100%;
  box-shadow: 0 0.6rem 1rem 0 rgb(0 0 0 / 6%);
  border-radius: 0.75rem;

  &.disabled {
    color: #9ba5b4;
    cursor: default;
  }
}

/* Tiers
-------------------------------------------------- */
.tier-bronze {
  background-color: #b1703a;
}

.tier-silver {
  background-color: #c7d8df;
}

.tier-gold {
  background-color: #ffe449;
}

.tier-platinum {
  background-color: #68dfcc;
}

.tier-card {
  z-index: 2;
}

.tier-next-card {
  width: 90%;
  margin: 0 auto;
  z-index: 1;
  margin-bottom: -1rem;
  padding: 1rem;
  padding-bottom: 1.5rem;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.update-tier-card {
  z-index: 100;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 400px;

  .btn-logout {
    width: unset;
    padding: 1rem;
  }
}

.tier-gallery {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tier-item {
  flex: 0.3 1;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: 0.8s ease-out;
  height: 200px;
  margin-right: 0.5rem;
  position: relative;

  .header {
    display: none;
    justify-content: space-between;
  }

  .body {
    display: none;
    flex-direction: column;
    flex-grow: 1;
    justify-content: end;
  }

  &.locked::after {
    content: '';
    background-color: #24252680;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 101%;
    height: 101%;
    margin: -2rem;
  }

  .inactive-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    .title {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      // position: absolute;
      // bottom: 2rem;
    }

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &.active {
    flex: 7;

    .inactive-header {
      display: none;
    }

    .body,
    .header {
      display: flex;
    }
  }

  &:not(.active) {
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}

/* Responsive
-------------------------------------------------- */
@media (max-width: 1400px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .page-title {
    margin-top: 3rem;

    img {
      height: 5rem;
      width: auto;
    }
  }

  .page-description {
    margin-bottom: 3rem;
  }

  .card {
    padding: 1rem;
  }

  .token-table .token-image {
    width: auto;
    height: 1.75rem;
    border-radius: 1.75rem;
  }

  .my-spacer {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }

  .welcome-card h3 {
    margin-right: 1rem;

    a {
      overflow-wrap: anywhere;
    }
  }

  .update-tier-card {
    width: 350px;
  }

  .tier-item.locked::after {
    margin: -1rem;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 14px;
  }

  .col-12 {
    padding: 0 !important;
  }

  .page-title img {
    height: 4rem;
    width: auto;
  }

  .token-table .table-col {
    min-width: 75px;
  }

  .token-table .table-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .token-table .table-body {
    padding: 1rem;
  }

  .welcome-card h3 {
    margin-right: 0.5rem;
  }

  .btn-logout,
  .btn-close,
  .btn-reload {
    width: 47.5px;
    height: 30px;
  }
}

.token-select {
  background: #242526;
  color: white;
  border: none;
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;
  font-size: 16px;

  &:focus-visible {
    outline: none;
  }
}

.btn-slippage {
  color: #9ba5b4 !important;
  background-color: #242526;
  margin-left: 8px;

  &.active {
    color: #000 !important;
    background-color: #ffc629;
  }
}